import * as THREE from 'three'
import Experience from './Experience.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as TWEEN from "@tweenjs/tween.js";

export default class Camera
{
    constructor()
    {
        this.experience = new Experience()
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.canvas = this.experience.canvas
        this.debug = this.experience.debug

        this.setInstance()

        if (this.debug.active) {
            this.addCameraHelper()
        }

    }

    setInstance()
    {
        this.instance = new THREE.PerspectiveCamera(35, this.sizes.width / this.sizes.height, 0.1, 100)
        this.instance.position.set(0, 0, 15)
        this.scene.add(this.instance)
    }

    setControls()
    {
        this.controls = new OrbitControls(this.instance, this.canvas)
        this.controls.enableDamping = true
    }

    resize()
    {
        this.instance.aspect = this.sizes.width / this.sizes.height
        this.instance.updateProjectionMatrix()
    }

    addCameraHelper()
    {
        const helper = new THREE.CameraHelper( this.instance );
        this.scene.add(helper);
    }

    update() 
    {
        //this.controls.update()
        //window.requestAnimationFrame(this.update);
        TWEEN.update();
    }

    mouseAdjustment(x, y) {
        this.instance.position.x = x
        this.instance.position.y = y
    }


    animateTo(x, y, z)
    {
        // Current position of camera
        const cameraCords = {
            x: this.instance.position.x,
            y: this.instance.position.y,
            z: this.instance.position.z
        }

        console.log('Current camera position: ', cameraCords.x, cameraCords.y, ' animating to ', x, y);

        const tween = new TWEEN.Tween(cameraCords)
            .to({ x: x, y: y, z: z }, 3000)
            .easing(TWEEN.Easing.Quadratic.InOut)
            .onUpdate((coords) => {
                this.instance.position.x = coords.x
                this.instance.position.y = coords.y
                this.instance.position.z = coords.z
            })
            .start();
                
    }
    
}