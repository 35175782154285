import Experience from '../Experience.js'
import Environment from './Environment.js'
import Floor from './Floor.js'
import Fox from './Fox.js'
import Desk from './Desk.js';
import Text from './Home/Text.js'
import * as THREE from 'three';
import Particles from './Particles.js';
import Mesh from '../World/ScrollScreen/Mesh.js';

export default class World
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources

        // Wait for resources
        this.resources.on('ready', () =>
        {
            // Setup
            //this.floor = new Floor()
            //this.fox = new Fox()
            ///;'this.text = new Text('Jack Nam')
            this.environment = new Environment()
            this.background = new Particles(2000)
            this.desk = new Desk();
        })

        // Watch for click event on the intro button
        if (document.getElementById('title-button')) {
            document.getElementById('title-button').addEventListener('click', this.onIntroClick.bind(this));
        }
    }

    update()
    {
        if(this.fox)
            this.fox.update()
    }

    // Handles the click event for the intro page
    onIntroClick(e) {
        e.preventDefault();


        // Slowly decrease the opacity of the intro items
        let items = document.getElementById('intro');
        let opacity = 1;
        let interval = setInterval(() => {
            opacity -= 0.1;
            items.style.opacity = opacity;
            if (opacity <= 0) {
                clearInterval(interval);
                items.style.display = 'none';
            }
        }, 50);

        // Slowly increase the opacity of the main portfolio
        /*
        let mainItems = document.getElementById('main');
        mainItems.style.opacity = 0;
        let mainOpacity = 0;
        let mainInterval = setInterval(() => {
            mainOpacity += 0.1;
            mainItems.style.opacity = mainOpacity;
            if (mainOpacity >= 1) {
                clearInterval(mainInterval);
            }
         }, 100);
        */

        // Navigate the camera to the tablet
   
        const desktopPosition = {
            x: this.experience.resources.items.deskModel.scene.children[0].position.x + 2,
            y: this.experience.resources.items.deskModel.scene.children[0].position.y + 5.75,
            z: this.experience.resources.items.deskModel.scene.children[0].position.z + 1,
        }

        this.experience.camera.animateTo(desktopPosition.x, desktopPosition.y, desktopPosition.z);
    }
}