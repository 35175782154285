import * as THREE from 'three'
import Experience from '../Experience.js'
import Time from '../Utils/Time.js'

export default class Desk {
    constructor()
    {
        this.experience = new Experience()
        this.time = new Time()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.debug = this.experience.debug
        
        // Debug
        if(this.debug.active)
        {
            this.debugFolder = this.debug.ui.addFolder('desk')
        }

        // Resources
        this.deskResource = this.resources.items.deskModel
        this.chairResource = this.resources.items.chairModel
        this.lampResource = this.resources.items.lampModel

        this.group = new THREE.Group();
        this.setModel()
    }

    setModel()
    {
        this.desk = this.deskResource.scene
        this.desk.scale.set(0.02, 0.02, 0.02)
        this.group.add(this.desk)

        this.desk.traverse((child) =>
        {
            if(child instanceof THREE.Mesh)
            {
                child.castShadow = true
            }
        })

        this.chair = this.chairResource.scene
        this.chair.scale.set(1.2, 1.2, 1.2)
        this.chair.rotateY(Math.PI * -0.15)
        this.chair.position.y = -2
        this.chair.position.x = 0.4
        this.chair.position.z = -2
        this.group.add(this.chair)

        this.chair.traverse((child) =>
        {
            if(child instanceof THREE.Mesh)
            {
                child.castShadow = true
            }
        })


        this.lamp = this.lampResource.scene
        this.lamp.scale.set(0.01, 0.01, 0.01)
        this.lamp.position.x = 1.7
        this.lamp.position.y = 0.7
        this.lamp.position.z = -0.1
        this.lamp.rotation.y = -0.5
        this.group.add(this.lamp)

        this.lamp.traverse((child) =>
        {
            if(child instanceof THREE.Mesh)
            {
                child.castShadow = true
            }
        })

        this.light = new THREE.PointLight('#ffbb73', 0.5, 10, Math.PI * 0.1, 0.25, 1);
        this.light.position.set(1.6, 1.6, -0.3);
        this.light.rotation.x = -1.3
        this.light.rotation.y = -1
        this.group.add(this.light);

        this.pointLightHelper = new THREE.PointLightHelper(this.light, 0.2);
        this.pointLightHelper.visible = false;
        this.scene.add(this.pointLightHelper);

        if (this.debug.active) {            
            this.debugFolder.add(this.group.position, 'x').min(-10).max(10).step(0.1)
            this.debugFolder.add(this.group.position, 'y').min(-10).max(10).step(0.1)
            this.debugFolder.add(this.group.position, 'z').min(-10).max(10).step(0.1)

            this.debugFolderChair = this.debugFolder.addFolder('Chair')
            this.debugFolderChair.add(this.chair.position, 'x').min(-10).max(10).step(0.1)
            this.debugFolderChair.add(this.chair.position, 'y').min(-10).max(10).step(0.1)
            this.debugFolderChair.add(this.chair.position, 'z').min(-10).max(10).step(0.1)

            this.debugFolderLamp = this.debug.ui.addFolder('Lamp')
            this.debugFolderLamp.add(this.lamp.position, 'x').min(-10).max(10).step(0.1)
            this.debugFolderLamp.add(this.lamp.position, 'y').min(-10).max(10).step(0.1)
            this.debugFolderLamp.add(this.lamp.position, 'z').min(-10).max(10).step(0.1)
            this.debugFolderLamp.add(this.lamp.rotation, 'x').min(-Math.PI).max(Math.PI).step(0.1).name('Rotation x')
            this.debugFolderLamp.add(this.lamp.rotation, 'y').min(-Math.PI).max(Math.PI).step(0.1).name('Rotation y')

            this.pointLightHelper.visible = true
            this.debugFolderLight = this.debug.ui.addFolder('Light')
            this.debugFolderLight.add(this.light.position, 'x').min(-10).max(10).step(0.1)
            this.debugFolderLight.add(this.light.position, 'y').min(-10).max(10).step(0.1)
            this.debugFolderLight.add(this.light.position, 'z').min(-10).max(10).step(0.1)
            this.debugFolderLight.add(this.light.rotation, 'x').min(-Math.PI).max(Math.PI).step(0.1).name('Rotation x')
            this.debugFolderLight.add(this.light.rotation, 'y').min(-Math.PI).max(Math.PI).step(0.1).name('Rotation y')
        }

        // Update position of group
        this.group.position.x = 1.5;
        this.group.position.y = 4.6;
        this.scene.add(this.group);
    }
}