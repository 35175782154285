import * as THREE from 'three';
import Experience from '../Experience.js';

export default class Particles
{
    constructor(count)
    {
        this.experience = new Experience();
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.count = count;

        this.setGeometry()
        this.setMaterial()
        this.setMesh()
    }

    setGeometry()
    {
        this.positions = new Float32Array(this.count * 3);
        this.colors = new Float32Array(this.count * 3);

        for (let i = 0; i  < this.count * 3; i++) {
            this.positions[i] = (Math.random() - 0.5) * 25;
            this.colors[i] = Math.random()
        }

        this.geometry = new THREE.BufferGeometry();
        this.geometry.setAttribute('position', new THREE.BufferAttribute(this.positions, 3))
        this.geometry.setAttribute('color', new THREE.BufferAttribute(this.colors, 3))
    }

    setMaterial()
    {
        this.material = new THREE.PointsMaterial({
            alphaMap: this.resources.items.particleTexture1,
            depthWrite: false,
            transparent: true,
            size: 0.25,
            sizeAttenuation: true,
            blending: THREE.AdditiveBlending,
            vertexColors: true,
        });
    }

    setMesh()
    {
        this.mesh = new THREE.Points(this.geometry, this.material)
        this.scene.add(this.mesh)
    }
}