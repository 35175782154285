export default [
    {
        name: 'environmentMapTexture',
        type: 'cubeTexture',
        path:
        [
            'textures/environmentMap/px.jpg',
            'textures/environmentMap/nx.jpg',
            'textures/environmentMap/py.jpg',
            'textures/environmentMap/ny.jpg',
            'textures/environmentMap/pz.jpg',
            'textures/environmentMap/nz.jpg'
        ]
    },
    {
        name: 'grassColorTexture',
        type: 'texture',
        path: 'textures/dirt/color.jpg'
    },
    {
        name: 'grassNormalTexture',
        type: 'texture',
        path: 'textures/dirt/normal.jpg'
    },
    {
        name: 'foxModel',
        type: 'gltfModel',
        path: 'models/Fox/glTF/Fox.gltf'
    },
    {
        name: 'deskModel',
        type: 'gltfModel',
        path: 'models/Desk/Desk.gltf'
    },
    {
        name: 'chairModel',
        type: 'gltfModel',
        path: 'models/Chair/Chair.gltf'
    },
    {
        name: 'lampModel',
        type: 'gltfModel',
        path: 'models/Lamp/Lamp.gltf'
    },
    {
        name: 'permanentMarkerFont',
        type: 'font',
        path: 'fonts/PermanentMarker.json'
    },
    {
        name: 'particleTexture1',
        type: 'texture',
        path: 'textures/particles/1.png'
    },
    {
        name: 'gradientTexture3',
        type: 'texture',
        path: 'textures/gradients/3.jpg'
    }
]
