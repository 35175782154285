import EventEmitter from './EventEmitter.js'

export default class Mouse extends EventEmitter
{
    constructor()
    {
        super()

        // Setup
        this.x = 0
        this.y = 0

        // Resize event
        window.addEventListener('mousemove', (event) =>
        {
            this.x = (event.clientX / window.innerWidth) * 2 - 1;
            this.y = -(event.clientY / window.innerHeight) * 2 + 1;

            // Prevents the first mousemove event from firing so the animation doesn't jump
            if (this.x === 0 && this.y === 0) {
                return;
            }
            this.trigger('mousemove')
        })
    }
}